import { useEffect, useState } from "react";

/** Use media queries in React */
export function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

/** Media query for phones */
export const useIsPhone = (): boolean => useMediaQuery("(max-width: 719px)");
/** Media query for tablets */
export const useIsTablet = (): boolean => useMediaQuery("(min-width: 720px)");
/** Media query for Desktop */
export const useIsDesktop = (): boolean => useMediaQuery("(min-width: 1280px)");
