import type { Sizes } from "@truepill/capsule-utils";
import { styled, theme } from "@truepill/capsule-utils";
import cn from "classnames";
import React, { ReactElement } from "react";

type Axis = "horizontal" | "vertical";
type SizesObj = { [K in Sizes]: Record<string, string> };

export interface SpacerProps extends React.HTMLAttributes<HTMLSpanElement> {
  /**
   * Whether the spacer should be vertical or horizontal
   * @remarks leaving it blank will apply it to both
   */
  axis?: Axis;
  /**
   * The size of the spacer, if the size is not present the default value is `md`.
   */
  size?: Sizes;
  /**
   * Applies the size of the spacer just for the desktop.
   */
  desktop?: Sizes;
  /**
   * Applies the size of the spacer just for the tablet.
   */
  tablet?: Sizes;
}

export const getSizes = (sizes: Sizes[]): SizesObj => {
  return sizes.reduce<SizesObj>((acc, curr) => {
    acc[curr] = {
      width: `$${curr}`,
      minWidth: `$${curr}`,
      height: `$${curr}`,
      minHeight: `$${curr}`,
    };
    return acc;
  }, {} as SizesObj);
};

export const generateCompoundVariants = (sizes: Sizes[]) => {
  const horizontal = sizes.map((size) => ({
    axis: "horizontal",
    fontSize: 0.5,
    size: size,
    css: {
      height: "1px",
      minHeight: "1px",
      width: `$${size}`,
      minWidth: `$${size}`,
    },
  }));
  const vertical = sizes.map((size) => ({
    axis: "vertical",
    size: size,
    css: {
      height: `$${size}`,
      minHeight: `$${size}`,
      width: "1px",
      minWidth: "1px",
    },
  }));
  return [...horizontal, ...vertical];
};

const sizes = getSizes(Object.keys(theme.sizes) as Sizes[]);
const StyledSpacer = styled("span", {
  background: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  variants: {
    axis: {
      horizontal: {},
      vertical: {},
    },
    size: { ...sizes },
    tablet: { ...sizes },
    desktop: { ...sizes },
  },
  compoundVariants: generateCompoundVariants(
    Object.keys(theme.sizes) as Sizes[]
  ),
  defaultVariants: {
    size: "md",
  },
});

/**
 * A Spacer component is used to give space between elements instead of using padding and margin
 */
export const Spacer = ({
  className,
  desktop,
  tablet,
  ...rest
}: SpacerProps): ReactElement => (
  <StyledSpacer
    className={cn("capsule", "spacer", className)}
    data-testid="capsule-spacer"
    desktop={{
      "@desktop": desktop,
    }}
    tablet={{
      "@tablet": tablet,
    }}
    {...rest}
  />
);
