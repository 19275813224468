import { styled, useThemeOverrides } from "@truepill/capsule-utils";
import React from "react";

const StyledButton = styled("button", {
  position: "relative",
  cursor: "pointer",
  transition: "0.1s background linear",
  fontFamily: "$base",
  "&:disabled": {
    cursor: "not-allowed",
    opacity: 0.5,
  },
  fontWeight: 700,
  // /* Theme specific styling */
  borderRadius: "$radii$sm",
  fontSize: "$sizes$md",
  lineHeight: "$sizes$md",
  variants: {
    size: {
      lg: {
        padding: "calc(1.25 * $space$md) calc(2.5 * $space$md)",
        borderRadius: "$radii$sm",
      },
      md: {
        padding: "$space$md $space$xl",
        borderRadius: "$radii$sm",
      },
      sm: {
        padding: "$space$sm $space$lg",
        borderRadius: "$radii$sm",
      },
      xs: {
        padding: "$space$2xs $space$md",
        borderRadius: "$radii$xs",
      },
      "2xs": {
        padding: "$space$4xs $space$xs",
        borderRadius: "$radii$xs",
        fontSize: "calc(0.875 * $sizes$md)",
        lineHeight: "calc(1.25 * $sizes$md)",
      },
    },
    variant: {
      primary: {
        background: "$primary-700",
        border: "2px solid $primary-700",
        color: "$white",
        "&:enabled": {
          "&:hover": {
            background: "$primary-900",
            borderColor: "$primary-900",
            color: "$white",
          },
          "&:active": {
            background: "$primary-900",
            borderColor: "$primary-900",
            color: "$white",
          },
        },
      },
      "primary-outline": {
        border: "2px solid $primary-700",
        color: "$primary-700",
        backgroundColor: "transparent",
        "&:enabled": {
          "&:hover": {
            background: "$primary-700",
            color: "$white",
          },
          "&:active": {
            background: "$primary-700",
            color: "$white",
          },
        },
      },
      "primary-text": {
        border: "2px solid transparent",
        color: "$primary-700",
        backgroundColor: "transparent",
        "&:enabled": {
          "&:hover": {
            color: "$primary-900",
          },
        },
      },
      "dark-text": {
        border: "2px solid transparent",
        color: "$black",
        backgroundColor: "transparent",
      },
      secondary: {
        background: "$secondary-700",
        border: "2px solid $secondary-700",
        color: "$white",
        "&:enabled": {
          "&:hover": {
            background: "$secondary-900",
            borderColor: "$secondary-900",
            color: "$white",
          },
          "&:active": {
            background: "$secondary-900",
            borderColor: "$secondary-900",
            color: "$white",
          },
        },
      },
      "secondary-outline": {
        border: "2px solid $secondary-700",
        color: "$secondary-700",
        backgroundColor: "transparent",
        "&:enabled": {
          "&:hover": {
            background: "$secondary-700",
            color: "$white",
          },
          "&:active": {
            background: "$secondary-700",
            color: "$white",
          },
        },
      },
      "secondary-text": {
        border: "2px solid transparent",
        color: "$secondary-700",
        backgroundColor: "transparent",
        "&:enabled": {
          "&:hover": {
            color: "$secondary-900",
          },
        },
      },
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
  },
});

/**
 * A base button.
 */
export const Button = React.forwardRef<
  React.ElementRef<typeof StyledButton>,
  React.ComponentProps<typeof StyledButton>
>(({ css, ...props }, forwardedRef) => {
  const overrides = useThemeOverrides("button");

  return (
    <StyledButton
      {...props}
      css={{ ...(overrides && overrides), ...css }}
      ref={forwardedRef}
    />
  );
});
