import { styled } from "@truepill/capsule-utils";
import cn from "classnames";
import React, { HTMLAttributes, ReactElement } from "react";

const StyledScreenReaderOnly = styled("span", {
  position: "absolute",
  width: "1px",
  height: "1px",
  padding: "0",
  margin: "-1px",
  overflow: "hidden",
  clip: "rect(0, 0, 0, 0)",
  whiteSpace: "nowrap",
  borderWidth: "0",
});

/**
  Hides an element visually without hiding it from screen readers.
 */
export const ScreenReaderOnly = ({
  className,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): ReactElement => (
  <StyledScreenReaderOnly
    className={cn("capsule", "screen-reader", className)}
    {...rest}
  />
);
