import type {
  CapsuleTheme,
  CapsuleThemeOverrides,
} from "@truepill/capsule-utils";
import {
  createTheme,
  globalStyles,
  ThemeContext,
} from "@truepill/capsule-utils";
import React, { FunctionComponent, ReactElement } from "react";
import { Helmet } from "react-helmet";

interface ThemeProps {
  /** Theme object. Any values passed here will replace the Truepill defaults.*/
  theme?: CapsuleTheme;
  /** Allows the override of the styling of individual components in Capsule*/
  overrides?: CapsuleThemeOverrides;
}

/**
 * The theme provider to wrap all other components. Allows you to override the default Capsule theme, as well as resetting default browser styles.
 *
 * @param theme - configuration for React Capsule. Sets to the Truepill default config if not present. Not all values need be passed into theme, any values omitted will default to the default theme values.
 * @param overrides - any style overrides for specific Capsule components.
 */
export const Theme: FunctionComponent<ThemeProps> = ({
  theme = {},
  overrides = {},
  children,
}): ReactElement => {
  globalStyles();
  const themeClassName = createTheme(theme);

  return (
    <>
      <Helmet>
        {/* Lato */}
        <link
          rel="stylesheet"
          href="https://use.typekit.net/ysv7wrb.css"
          data-capsule-lato
        />
      </Helmet>
      <ThemeContext.Provider value={overrides}>
        <div className={themeClassName} data-capsule-theme={true}>
          {children}
        </div>
      </ThemeContext.Provider>
    </>
  );
};
