import * as React from "react";

import type { CapsuleThemeOverrides } from "../themes/themeContext";
import { ThemeContext } from "../themes/themeContext";

/**
 * Returns the part of theme overrides defined by the key arg.
 * @param key - The part of the theme overrides required
 */
export const useThemeOverrides = (
  key: keyof CapsuleThemeOverrides
): CapsuleThemeOverrides[typeof key] => {
  const themeOverrides = React.useContext(ThemeContext);

  return themeOverrides[key];
};
