import Alert from "@reach/alert";
import { Portal } from "@reach/portal";
import {
  CSS,
  styled,
  useIsTablet,
  useThemeOverrides,
} from "@truepill/capsule-utils";
import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React, { HTMLAttributes, ReactElement, useEffect } from "react";
import {
  AlertOctagon,
  AlertTriangle,
  CheckCircle,
  Info,
  X,
} from "react-feather";

import { Button } from "../button/Button";
import { ScreenReaderOnly } from "../screen-reader-only/ScreenReaderOnly";
import { Text } from "../text/Text";
import { getCoordinates, getDefaultPositions } from "./toast-functions";

type PositionVertical = "top" | "bottom" | "center";
type PositionHorizontal = "left" | "right" | "center";

export type ToastPosition = {
  vertical?: PositionVertical;
  horizontal?: PositionHorizontal;
};

const toastColorScheme = ["primary", "white", "pastel"] as const;
export type ToastColorScheme = typeof toastColorScheme[number];
const toastType = ["info", "success", "warning", "error"] as const;
export type ToastType = typeof toastType[number];
export type ColorProps = "borderColor" | "color" | "backgroundColor";

export interface ToastProps extends HTMLAttributes<HTMLDivElement> {
  /** Text used in the action button */
  actionText?: string;
  /** Show decorative border on the left */
  borderLeft?: boolean;
  /** Determines the color scheme of the toast */
  color?: ToastColorScheme;
  /** Determines if the toast icon is displayed*/
  icon?: boolean;
  /** On action Click. Will not work if actionText is not defined */
  onActionClick?: () => void;
  /** callback function which runs when a user clicks the dismiss button on the toast. The dismiss button is displayed only if this function is defined.*/
  onDismiss?: () => void;
  /** callback function which runs when the timeout is complete*/
  onTimeout?: () => void;
  /** Determines the position of the toast*/
  position?: ToastPosition;
  /** Determines the variant of the toast. Variants can be changed by updating the theme in the Theme component*/
  state?: ToastType;
  /** Set the time between the toast showing and hiding. Will not work if onTimeout is not defined*/
  timeout?: number;
  /** Determines the visibility of the toast*/
  visible?: boolean;
  /** additional classnames*/
  className?: string;
  /** Classname applied to root element */
  rootClassName?: string;
  /** CSS override for the component */
  css?: CSS;
}

const getColorVariants = (colorProps: ColorProps[]) => {
  const colors = [];
  for (const color of toastColorScheme) {
    for (const state of toastType) {
      const cssProps = {} as CSS;
      if (color === "white") {
        if (colorProps.includes("backgroundColor")) {
          cssProps.backgroundColor = `$white`;
        }
        if (colorProps.includes("color")) {
          cssProps.color = `$functional-${state}-dark`;
        }
        if (colorProps.includes("borderColor")) {
          cssProps.borderColor = `$functional-${state}-dark`;
        }
      } else if (color === "pastel") {
        if (colorProps.includes("backgroundColor")) {
          cssProps.backgroundColor = `$functional-${state}-light`;
        }
        if (colorProps.includes("color")) {
          cssProps.color = `$functional-${state}-dark`;
        }
        if (colorProps.includes("borderColor")) {
          cssProps.borderColor = `$functional-${state}-dark`;
        }
      } else {
        if (colorProps.includes("backgroundColor")) {
          cssProps.backgroundColor = `$functional-${state}-dark`;
        }
        if (colorProps.includes("color")) {
          cssProps.color = `$functional-${state}-light`;
        }
        if (colorProps.includes("borderColor")) {
          cssProps.borderColor = `$white`;
        }
      }
      colors.push({
        color: color,
        state: state,
        css: { ...cssProps },
      });
    }
  }
  return colors;
};

const getBorderColor = (state: ToastType, color: ToastColorScheme) => {
  switch (color) {
    case "white":
    case "pastel":
      return `$functional-${state}-dark`;
    default:
      return "white";
  }
};

const iconMap: Record<ToastType, ReactElement> = {
  info: <Info aria-hidden />,
  success: <CheckCircle aria-hidden />,
  warning: <AlertTriangle aria-hidden />,
  error: <AlertOctagon aria-hidden />,
};

const emptyVariants = {
  state: {
    info: {},
    success: {},
    warning: {},
    error: {},
  },
  color: {
    primary: {},
    white: {},
    pastel: {},
  },
};

const defaultVariants = {
  state: "info",
  color: "primary",
};

const StyledToast = styled(Alert, {
  width: "100%",
  display: "flex",
  alignItems: "center",
  borderRadius: "$sm",
  boxShadow:
    "0 6px 8px -6px rgba(24, 39, 75, 0.12), 0 8px 16px -6px rgba(24, 39, 75, 0.08)",
  variants: { ...emptyVariants },
  defaultVariants: { ...defaultVariants },
  compoundVariants: [
    ...getColorVariants(["color", "backgroundColor", "borderColor"]),
  ],
  padding: "0 $2xs 0 $md",
});

const ToastContent = styled("div", {
  padding: "$md 1rem $md 0",
  flex: "1",
  [`& ${Text}`]: {
    fontSize: "14px",
    lineHeight: "20px",
  },
});

const IconWrapper = styled("div", {
  padding: "0 1rem 0 0",
});

const ActionButton = styled(Button, {
  marginLeft: "auto",
  padding: "0 $md",
  position: "relative",
  top: "-2px",
  ['&[class*="variant-primary-text"]']: {
    color: "CurrentColor",
    "&:enabled": {
      "&:focus, &:active, &:hover": {
        color: "CurrentColor",
      },
    },
  },
});

const Dismiss = styled("button", {
  background: "none",
  border: "none",
  cursor: "pointer",
  padding: 0,
  width: "48px",
  height: "48px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "CurrentColor",
});

const MotionToast = styled(motion.div, {
  position: "fixed",
  display: "flex",
  width: "calc(100% - 10px)",
  zIndex: 1,
  "@tablet": {
    width: "327px",
  },
  '&[data-position-vertical="top"]': {
    top: "30px",
  },
  '&[data-position-vertical="bottom"]': {
    bottom: "30px",
  },
  '&[data-position-vertical="center"]': {
    bottom: "30px",

    "@tablet": {
      bottom: "50%",
    },
  },
  '&[data-position-horizontal="left"]': {
    right: "50%",
    transform: "translateX(50%)",
    "@tablet": {
      left: "30px",
      transform: "translateX(0)",
    },
  },
  '&[data-position-horizontal="right"]': {
    right: "50%",
    transform: "translateX(50%)",
    "@tablet": {
      right: "30px",
      transform: "translateX(0)",
    },
  },
  '&[data-position-horizontal="center"]': {
    right: "50%",
    transform: "translateX(50%)",
  },
});

/**
 * A toast is used to communicate short feedback messages based on user actions.
 */

export const Toast = ({
  actionText,
  borderLeft = true,
  color = "primary",
  icon = false,
  onDismiss,
  onActionClick,
  onTimeout,
  position = { vertical: "bottom", horizontal: "center" },
  timeout = 5000,
  state = "info",
  visible = false,
  children,
  className,
  rootClassName,
  css,
  ...rest
}: ToastProps): ReactElement => {
  const isTablet = useIsTablet();
  position = getDefaultPositions(position);
  useEffect(() => {
    if (!onTimeout || !visible) return;
    const timeoutId = setTimeout(onTimeout, timeout);
    return () => clearTimeout(timeoutId);
  }, [onTimeout, timeout, visible]);
  const overrides = useThemeOverrides("toast");
  const borderLeftStyle = {} as CSS;
  if (borderLeft) {
    borderLeftStyle.borderLeft = `4px solid ${getBorderColor(state, color)}`;
  }

  return (
    <Portal>
      <AnimatePresence>
        {visible && (
          <MotionToast
            initial={{
              opacity: 0,
              ...getCoordinates("initial", position, isTablet),
            }}
            animate={{
              opacity: 1,
              ...getCoordinates("animate", position, isTablet),
            }}
            exit={{
              opacity: 0,
              ...getCoordinates("exit", position, isTablet),
              transition: { type: "tween", duration: 0.2 },
            }}
            transition={{
              type: "spring",
              duration: 0.4,
            }}
            data-position-vertical={position.vertical}
            data-position-horizontal={position.horizontal}
            className={rootClassName}
          >
            <StyledToast
              {...rest}
              className={cn("capsule", "toast", className)}
              data-testid="toast-wrapper"
              state={state}
              color={color}
              css={{
                ...borderLeftStyle,
                ...overrides,
                ...css,
              }}
            >
              {icon && (
                <IconWrapper data-testid="toast-icon">
                  {iconMap[state]}
                </IconWrapper>
              )}
              <ToastContent>{children}</ToastContent>
              {actionText && (
                <ActionButton
                  variant="primary-text"
                  onClick={onActionClick}
                  size="2xs"
                  className="actionButton"
                >
                  <Text variant="body-sm" bold underline as="span">
                    {actionText}
                  </Text>
                </ActionButton>
              )}
              {onDismiss && (
                <Dismiss type="button" onClick={onDismiss}>
                  <X aria-hidden />
                  <ScreenReaderOnly>Dismiss</ScreenReaderOnly>
                </Dismiss>
              )}
            </StyledToast>
          </MotionToast>
        )}
      </AnimatePresence>
    </Portal>
  );
};
