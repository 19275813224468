import type { CSS } from "@stitches/react";
import * as React from "react";

type CapsuleElements =
  | "accordion"
  | "accordionContent"
  | "accordionItem"
  | "accordionTrigger"
  | "autocomplete"
  | "autocompleteTextField"
  | "autocompleteTrigger"
  | "avatar"
  | "bannerAlert"
  | "breadcrumbs"
  | "button"
  | "card"
  | "checkbox"
  | "checkboxIndicator"
  | "chip"
  | "divider"
  | "dividerText"
  | "formGroup"
  | "grid"
  | "gridItem"
  | "header"
  | "loadingSpinner"
  | "modal"
  | "modalOverlay"
  | "popoverContent"
  | "popoverArrow"
  | "progressBar"
  | "radioLabel"
  | "radioIndicator"
  | "radioIndicatorContainer"
  | "select"
  | "selectTrigger"
  | "text"
  | "textField"
  | "textFieldWrapper"
  | "toast"
  | "toggleLabel"
  | "toggleSwitch"
  | "tooltipArrow"
  | "tooltip";

export type CapsuleThemeOverrides = {
  [T in CapsuleElements]?: CSS;
};

export const ThemeContext = React.createContext<CapsuleThemeOverrides>({});
