import type { Position as ReachUiPosition } from "@reach/tooltip";

import type { TooltipPosition } from "./Tooltip";

type PRect = Partial<DOMRect> & {
  readonly bottom: number;
  readonly height: number;
  readonly left: number;
  readonly right: number;
  readonly top: number;
  readonly width: number;
};

export const horizontalPosition: (
  top: (triggerRect: PRect, tooltipRect: PRect) => number | undefined
) => ReachUiPosition = (top) => (triggerRect, tooltipRect) => {
  if (!triggerRect || !tooltipRect) return {};
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: top(triggerRect, tooltipRect),
  };
};

export const verticalPosition: (
  left: (triggerRect: PRect, tooltipRect: PRect) => number | undefined
) => ReachUiPosition = (left) => (triggerRect, tooltipRect) => {
  if (!triggerRect || !tooltipRect) return {};
  const top =
    triggerRect.top -
    tooltipRect.height / 2 +
    triggerRect.height / 2 +
    window.scrollY;
  return {
    left: left(triggerRect, tooltipRect),
    top,
  };
};

export const top: ReachUiPosition = horizontalPosition(
  (triggerRect, tooltipRect) => {
    return triggerRect.top - 8 - tooltipRect.height + window.scrollY;
  }
);

export const bottom: ReachUiPosition = horizontalPosition((triggerRect) => {
  return triggerRect.bottom + 8 + window.scrollY;
});

export const right = verticalPosition((triggerRect) => {
  return triggerRect.right + 8 + window.scrollX;
});

export const left = verticalPosition((triggerRect, tooltipRect) => {
  const triggerLeft = triggerRect.left - 8;
  return triggerLeft - tooltipRect.width + window.scrollX;
});

export const calculateArrowPosition = (
  triggerRect: DOMRect | null,
  position: TooltipPosition
): { left: number | string; top: number | string } => {
  if (!triggerRect) return { left: "", top: "" };
  const verticalTop = triggerRect.top + window.scrollY;
  const horizontalLeft = triggerRect.left - 10 + triggerRect.width / 2;
  switch (position) {
    case "top":
      return {
        left: horizontalLeft,
        top: triggerRect.top - 10 + window.scrollY,
      };
    case "right":
      return {
        left: triggerRect.left + triggerRect.width,
        top: verticalTop,
      };
    case "bottom":
      return {
        left: horizontalLeft,
        top: triggerRect.bottom + window.scrollY,
      };
    case "left":
      return {
        left: triggerRect.right - 10 - triggerRect.width,
        top: verticalTop,
      };
  }
};
