import type { ToastPosition } from "./Toast";

export const getDefaultPositions = (position: ToastPosition): ToastPosition => {
  let { horizontal, vertical } = position;

  if (!horizontal || !vertical) {
    if (horizontal) {
      if (horizontal !== "center") {
        vertical = "center";
      } else {
        vertical = "bottom";
      }
    } else if (vertical) {
      horizontal = "center";
    } else {
      vertical = "bottom";
      horizontal = "center";
    }
  }

  return { horizontal, vertical };
};

export const getCoordinates = (
  animationStage: "initial" | "animate" | "exit",
  position: ToastPosition,
  isTablet: boolean
): {
  x: string | number;
  y: string | number;
} => {
  const animationOffset = 50;
  let varX: string | number = 0;
  let varY: string | number = 0;
  const { horizontal, vertical } = position;

  const positionMappings = {
    isMobile: {
      // On mobile, the top toasts stay on the top and adjust to the size of the viewport
      top: {
        left: { x: "50%", y: -animationOffset },
        center: { x: "50%", y: -animationOffset },
        right: { x: "50%", y: -animationOffset },
      },
      // On mobile, the center and bottom toasts appear at the bottom and adjust to the size of the viewport
      center: {
        left: { x: "50%", y: animationOffset },
        center: { x: "50%", y: animationOffset },
        right: { x: "50%", y: animationOffset },
      },
      bottom: {
        left: { x: "50%", y: animationOffset },
        center: { x: "50%", y: animationOffset },
        right: { x: "50%", y: animationOffset },
      },
    },
    isTablet: {
      // In tablet/desktop, as positioning is different for each toast
      top: {
        left: { x: -animationOffset, y: 0 },
        center: { x: "50%", y: -animationOffset },
        right: { x: animationOffset, y: 0 },
      },
      center: {
        left: { x: -animationOffset, y: "50%" },
        center: { x: "50%", y: "50%" },
        right: { x: animationOffset, y: "50%" },
      },
      bottom: {
        left: { x: -animationOffset, y: 0 },
        center: { x: "50%", y: animationOffset },
        right: { x: animationOffset, y: 0 },
      },
    },
  };

  switch (animationStage) {
    case "initial":
    case "exit":
      // For initial and exit positions we get the values from the positionMappings
      if (vertical && horizontal) {
        varX =
          positionMappings[isTablet ? "isTablet" : "isMobile"][vertical][
            horizontal
          ].x;
        varY =
          positionMappings[isTablet ? "isTablet" : "isMobile"][vertical][
            horizontal
          ].y;
      }
    case "animate":
      if (isTablet) {
        // In tablet/desktop the values for x and y should be 0 as it animates to the
        // original css positioning, except for centered toasts, for which we have to
        // reset the css transform values that are being overriden by framer motion.
        if (horizontal === "center") {
          varX = "50%";
        }
        if (vertical === "center") {
          varY = "50%";
        }
      } else {
        // On mobile, the toasts are always horizontally centered.
        varX = "50%";
      }
      break;
  }
  return { y: varY, x: varX };
};
